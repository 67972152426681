<template>
    <div v-if="settingTabs?.formaTab">
        <div :class="`flex xs:overflow-x-scroll lg:overflow-hidden ${settingTabs?.classFather} ${validTabButton == true ? 'flex-wrap justify-between':''}`" >

            <ul class="flex items-start -mb-px" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
                <li :class="`${tab.class} h-[43px] lg:w-auto xs:pr-[10px] lg:pr-[40px]`" role="presentation" v-for="(tab, index) in props.tabsData" :key="index">
                    
                    <p class="tabs text-[#727272] font-[400] cursor-pointer text-[15px] pb-[6px] font-WT"
                    :id="`${tab.name}-tab`" :data-tabs-target="`#${tab.name}`" type="button" role="tab" :aria-controls="`${tab.name}`" aria-selected="false"
                        @click= "activeTab"
                    >{{ tab.id }}
                    </p>
                </li>
            
                <li  v-if="settingTabs?.headerBefore">
                    <slot name="headerBefore"></slot>
                </li>
                
            
            
            </ul>


            <slot name="headerAfter" v-if="validTabButton"></slot>

        </div>
        <div id="myTabContent" class="mt-[0]">
            <div class="hidden pl-[0] pt-[0] rounded-lg " :id="`${tab.name}`" role="tabpanel" :aria-labelledby="`${tab.name}-tab`" v-for="(tab,index) in props.tabsData" :key="index">
                <slot :name="tab.name"></slot>
            </div>

        </div>

    </div>

    <div v-else>
        <div :class="`mb-4 flex ${settingTabs?.classFather}`" >

            <ul class="flex bg-[#F1F1F1] rounded-[10px] w-[346+px] px-[4px] py-[7px]" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
                <li class="li border-r-[1px] last:border-r-[0px]  border-solid border-[#C4C4C4] flex items-center justify-center opacity-100" role="presentation" 
                v-for="(tab, index) in props.tabsData" :key="index" aria-selected="false" >
                    
                    <p 
                    :class="`tabs1 px-[24px] opacity-100 cursor-pointer text-[#000000] text-[14px] p-[0] flex items-center justify-center text-center w-full h-full `"
                    :id="`${tab.name}-tab`" :data-tabs-target="`#${tab.name}`" role="tab" type="button" :aria-controls="`${tab.name}`" aria-selected="false"
                        @click = "activeTab"
                    >{{ tab.id }}
                    </p>
                </li>

               
                


            </ul>

        </div>

        <div id="myTabContent" class="mt-[0] pl-[16px]">
            <div class="hidden pl-[0] pt-[0] rounded-lg " :id="`${tab.name}`" role="tabpanel" :aria-labelledby="`${tab.name}-tab`" v-for="(tab,index) in props.tabsData" :key="index">
                <slot :name="tab.name"></slot>
            </div>

        </div>
    </div>

 

</template>

<script setup>
    import {initTabs} from 'flowbite';

    const props = defineProps({
        tabsData: {type:Array},
        settingTabs: {
            type: Array,
            required: true
        }
        
    });

    const validTabButton = ref(true)

    const activeTab = ($event) =>  {
        switch ($event.target.id ) {
            case "equipos-tab" :
            case "proyectos-tab" :
                validTabButton.value = true
                break;
        
            default:
                validTabButton.value = false
                break;
        }
    }


  

    // initialize components based on data attribute selectors
    onMounted(() => {
    
        initTabs();
    })

    



</script>

<style lang="scss" scoped>
    #myTabContent {
        margin-top: 0;
    }
    .tabs {
        @apply hover:text-[#727272] relative aria-selected:border-[#0A47ED] aria-selected:text-[#0A47ED] aria-selected:after:content-[''] 
        aria-selected:after:absolute aria-selected:after:left-[0] aria-selected:after:bottom-[-5px] aria-selected:after:w-full aria-selected:after:h-[4.3px] aria-selected:after:bg-[#0A47ED] aria-selected:after:rounded-[5px]; 
    }

    .tabs1 {
        @apply aria-selected:after:bg-[#F1F1F1] aria-selected:text-[#0A47ED] aria-selected:font-[700] ;
    }


    .li {
        .tabs1[aria-selected='false'] {
            @apply font-WT opacity-100 text-[#212322];
        }
    }

    .li::nth-child(1) {
        .tabs1 {
            @apply pl-[0];
        }
    }

   
   
    
</style>